<template>
  <base-dialog class="p-3" :title="$t('changeOrderType')" customActivator>
    <template #custom-activator="{ events: { handleOpenDialog } }">
      <v-row>
        <v-col class="d-flex flex-wrap align-center ga-2">
          <label
            class="col-12 col-sm-4 col-md-3 col-lg-2"
            style="max-width: 150px"
          >
            {{ `${$t("changeOrderType")}:` }}
          </label>

          <section
            class="col elevation-2 rounded cursor-pointer d-flex justify-space-between"
            @click="handleOpenDialog"
          >
            <span class="text-capitalize">{{ orderTo }}</span>
            <v-icon>mdi-menu-down</v-icon>
          </section>
        </v-col>
      </v-row>
    </template>
    <template #content>
      <SettingOrder />
    </template>
  </base-dialog>
</template>
<script>
import { i18n } from "@/plugins/i18n";
import { orderTypeName } from "@/store/modules/orderType/constNames";

export default {
  name: "InputSelectOrderType",
  props: {
    defaultOrderType: { type: String, required: true },
    handlerSelectOrderType: { type: Function, required: true },
  },
  components: {
    SettingOrder: () => import("@/components/shared/SettingOrder/SettingOrder"),
  },
  computed: {
    orderTypeItems() {
      return Object.keys(orderTypeName).map((key) => {
        return {
          text: i18n.t(key),
          value: key,
        };
      });
    },
    orderTo() {
      return i18n.t(this.defaultOrderType);
    },
  },
};
</script>
